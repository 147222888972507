import React, { useEffect, useState, useContext } from 'react'
import Banner from '../Banner';
import  Accept from './Accept';
import { AuthContext } from '../../context/AuthContext';

const testData = [
    [ {
        id: 1,
        image:"./images/food1.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"2",
        alt:"food"
    },
    {
        id: 2,
        image:"./images/food2.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"3",
        alt:"food"
    },
    {
        id: 3,
        image:"./images/food3.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"1",
        alt:"food"
    }
],
[ {
    id: 4,
    image:"./images/food2.png",
    itemName:"Spicy Noodle",
    itemPara:"Requiment or Optional form customer",
    amt:"Amount",
    num:"3",
    alt:"food"
},
{
    id: 5,
    image:"./images/food3.png",
    itemName:"Spicy Noodle",
    itemPara:"Requiment or Optional form customer",
    amt:"Amount",
    num:"1",
    alt:"food"
}],
[
    {
        id: 6,
        image:"./images/food2.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"3",
        alt:"food"
    }
]
]

const MerchantFlow2 = () => {
    const auth = useContext(AuthContext);
    let [orderData, setOrderData] = useState([]);
    let shopId = '660a620ff16175693097b78f';

    useEffect(() => {
        try{
            // get_merchant_initial_order
            const merchantOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_merchant_order/${shopId}/initiated`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                  }
            })
            const datas = await response.json();
            setOrderData(datas.merchantOrder);
            // console.log(datas);  
            }
            merchantOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])
        // console.log(orderData);
        // update_merchant_order_Status

  return (
    <div>
        <Banner/>
        {
            orderData.map((data, index)=> <Accept key={index} data={data}/>)

        }
        
    </div>
  )
}

export default MerchantFlow2