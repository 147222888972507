import React, { useState, useEffect, useContext } from 'react'
import Banner from '../Banner';
import Rooms from '../RoomDetails/Rooms';
import YbotButton from './YbotButton';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';


const MerchantFlow4 = () => {
  const auth = useContext(AuthContext);
  let [orderData, setOrderData] = useState([]);
  let shopId = '660a620ff16175693097b78f';
  const location = useLocation();
  const { state } = location;
  console.log(state);
    useEffect(() => {
        try{
            const merchantOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_merchant_order/${shopId}/preparation-done`, {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            })
            const datas = await response.json();
            setOrderData(datas.merchantOrder);
            }
            merchantOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])
console.log(orderData);
  return (
    <>
    <Banner/>
    {orderData.map((data, index) => {
      console.log('state.orderId');
       console.log(data);
      // console.log(state.orderId);
        return(
    <div className='room-sec'>
        <Rooms key={index} data={data}/>
        {state && state.orderId && data.orderId === state.orderId ? <YbotButton key={index} orderId={data.orderId} callOrder={state.orderId && state.orderId} placeItemCodeDB={data.placeItemCode} bridgeIdDB = {data.bridgeId} /> : <YbotButton key={index} orderId={data.orderId} callOrder={null} placeItemCodeDB={data.placeItemCode} bridgeIdDB = {data.bridgeId} /> }
        {/* <YbotButton key={data.index} orderId={data.orderId} /> */}
        {/* <YbotButton key={data.index} orderId={data.orderId} callOrder={state.orderId && state.orderId}/> */}
    </div>
       )
      })
      }  

    </>
  )
}

export default MerchantFlow4