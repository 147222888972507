import React from 'react'
import RoomData from './RoomData'
// import RoomData2 from './RoomData2'
// import RoomData1 from './RoomData1'
import { Link } from 'react-router-dom'
// import  ButtonMerchant from './ARButton'
//  import Done from '../Page2/Done'


const Rooms = ({data}) => {
  return (
    <>
            <section>
            <div className='room-text'>
                <div className='room-det'>
                    <h2 className='room-no'>Room {data.roomNo}</h2>
                    <p className='room-date'>Order Time: {data.createdAt}</p>
                </div>
                <div className='cc-btn'>
                    <Link to="#">Contact Customer</Link>
                </div>
            </div>
            <RoomData key={data.orderId} orderItem={data.items}/>
            </section>
    

    {/* <section className='room-sec'>
        <div className='room-text'>
            <div>
                <h2 className='room-no'>Room 103</h2>
                <p className='room-date'>Order Time: 15:45 | 05/MAR/2024</p>
            </div>
            <div className='cc-btn'>
                <Link to="#">Contact Customer</Link>
            </div>
        </div>
        <RoomData2/>
        <ButtonMerchant/>

    </section> */}
    {/* <section className='room-sec'>
        <div className='room-text'>
            <div>
                <h2 className='room-no'>Room 103</h2>
                <p className='room-date'>Order Time: 15:45 | 05/MAR/2024</p>
            </div>
            <div className='cc-btn'>
                <Link to="#">Contact Customer</Link>
             </div>
        </div>
        <RoomData1/>
        <ButtonMerchant/>

    </section> */}
    
    
    </>
  )
}

export default Rooms