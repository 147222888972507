import React from 'react'

const Popup = () => {
  return (
    <>
    <section className='pop-sec'>
        <div className='popup-div'>
            <h4>Why did you decide to reject this order :</h4>
            <form>
                <div className='shop-status'>
                   <p><input type='radio'/>Out of stock</p> 
                   <p><input type='radio'/>Shop closed</p> 
                    <p><input type='radio'/>Temporarily close</p>
                </div>
                <div className='text-area'>
                    <label>Others:</label><br></br>
                    <textarea></textarea>
                </div>
                <button className='submit-btn'>Submit</button>
            </form>

        </div>
    </section>
    </>
  )
}

export default Popup