import React, { useState, useEffect, useContext } from 'react'
import Banner from '../Banner';
import  Accept2 from './Accept2';
import { AuthContext } from '../../context/AuthContext';

const testData = [
    [ {
        image:"./images/food1.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"2",
        alt:"food"
    },
    {
        image:"./images/food2.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"3",
        alt:"food"
    },
    {
        image:"./images/food3.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"1",
        alt:"food"
    }
],
[ {
    image:"./images/food2.png",
    itemName:"Spicy Noodle",
    itemPara:"Requiment or Optional form customer",
    amt:"Amount",
    num:"3",
    alt:"food"
},
{
    image:"./images/food3.png",
    itemName:"Spicy Noodle",
    itemPara:"Requiment or Optional form customer",
    amt:"Amount",
    num:"1",
    alt:"food"
}],
[
    {
        image:"./images/food2.png",
        itemName:"Spicy Noodle",
        itemPara:"Requiment or Optional form customer",
        amt:"Amount",
        num:"3",
        alt:"food"
    }
]
]

const MerchantFlow6 = () => {
    const auth = useContext(AuthContext);
    let [orderData, setOrderData] = useState([]);
    let shopId = '660a620ff16175693097b78f';
    useEffect(() => {
        try{
            const merchantOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_merchant_order/${shopId}/deliveryCompleted`, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                  }
            })
            const datas = await response.json();
            setOrderData(datas.merchantOrder);
            console.log(datas.merchantOrder);
            }
            merchantOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])
  return (
    <div>
        <Banner/>
        {
            orderData.map((data)=> <Accept2 data={data.items}/>)

        }
        
    </div>
  )
}

export default MerchantFlow6