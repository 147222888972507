import React, { useState, useEffect, useContext } from 'react'
import Banner from '../Banner';
import Rooms from '../RoomDetails/Rooms'
import DeliveryInProgress from './DeliveryInProgress';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const MerchantFlow5 = () => {
  let [orderData, setOrderData] = useState([]);
  const auth = useContext(AuthContext);
  console.log(auth);
  let shopId = '660a620ff16175693097b78f';
  const location = useLocation();
  // const { state } = location;
  const state = location.state || {};
  console.log('flow5state');
  console.log(state);
    useEffect(() => {
        try{
            const merchantOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_merchant_order/${shopId}/ongoingDelivery`, {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            })
            const datas = await response.json();
            console.log(datas);
            setOrderData(datas.merchantOrder);
            }
            merchantOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])

  return (
    <>
     <Banner/>
     {orderData.map((data, index) => {
      return(
    <div className='room-sec'>
        <Rooms key={data.orderId} data={data}/>
        <DeliveryInProgress key={index} state={state} data={data} />
    </div>
     )
    })
    }  
    
    </>
  )
}

export default MerchantFlow5