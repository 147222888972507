import React, { useState, useEffect, useContext } from 'react'
import Banner from '../Banner';
import Rooms from '../RoomDetails/Rooms';
import Done from './Done';
import { AuthContext } from '../../context/AuthContext';

const MerchantFlow3 = () => {
  const auth = useContext(AuthContext);
  let [orderData, setOrderData] = useState([]);
  let shopId = '660a620ff16175693097b78f';

    useEffect(() => {
        try{
            // get_merchant_initial_order
            const merchantOrder = async () => {
            const response =  await fetch(`https://ybot-backend.salvator.app/api/get_merchant_order/${shopId}/accepted`, {
              headers: {
                'Authorization': `Bearer ${auth.token}`
              }
            })
            const datas = await response.json();
            setOrderData(datas.merchantOrder);
            }
            merchantOrder()
        } catch(err) {
            console.log(err);
        }
    }, [])

  return (
    <>
      <Banner/>
      {orderData.map((data, index) => {
        return(
      <div className='room-sec'>
          <Rooms key={index} data={data}/>
          <Done key={index} orderId={data.orderId}/>
      </div>
        )
      })
      } 

    </>
  )
}

export default MerchantFlow3