// src/App.js
import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import  MerchantFlow2 from './Components/AcceptOrder/MerchantFlow2'
 import MerchantFlow3 from './Components/Page2/MerchantFlow3'
import MerchantFlow4 from './Components/Page2/MerchantFlow4'
import MerchantFlow5 from './Components/Page2/MerchantFlow5'
import MerchantFlow6 from './Components/Page2/MerchantFlow6'
import Popup from './Components/Popup';
import { useAuth } from "./hooks/auth-hook";
import Login from "./Pages/Login";
import { AuthContext } from "./context/AuthContext";
import AuthHandler from './AuthHandler';
import MerchantProfileEdit1 from './Pages/MerchantProfileEdit1';
import MerchantProfileOrderHistory from './Pages/MerchantProfileEdit2';
import  MerchantProfileEdit2 from './Pages/MerchantProfileEdit2';
import MerchantPage2 from './Pages/MerchantPage2'
// import MerchantProfileEdit2 from './Pages/MerchantProfileOrderHistory'

import liff from '@line/liff';

const App = () => {
   const auth = useContext(AuthContext);
  // const { isLoggedIn, token, login, logout, userId } = useAuth();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  // const [profile, setProfile] = useState(null);
  // console.log('App auth');
  //   console.log(auth);
  //const { isLoggedIn } = useContext(AuthContext);
  //console.log(isLoggedIn);
  return (
    <Routes>
      {auth.isLoggedIn ? (
        <>
          <Route path='/' element={<MerchantFlow2/>}/>
          <Route path='/prepare-order' element={<MerchantFlow3/>}/> 
          <Route path='/prepare-delivery' element={<MerchantFlow4/>}/>
          <Route path='/delivery-process' element={<MerchantFlow5/>}/>
          <Route path='/delivery-done' element={<MerchantFlow6/>}/>
          <Route path='/Popup' element={<Popup/>}/>
          <Route path="/profile/:userId" element={<div className="App"><MerchantProfileEdit1/></div>} />
          <Route path="/order-detail/:orderId" element={<div className="App"><MerchantProfileOrderHistory/></div>} />
          <Route path="/merchant-profile-edit2" element={<div className="App"><MerchantProfileEdit2/></div>} />
          <Route path="/order-history" element={<div className='App'><MerchantPage2/></div>}/>
          <Route path="/register" element={<Login />} />
        </>
      ) : (
        <>
            <Route path="/register" element={<Login />} />
        </>
      )}
    </Routes>
  );
};

const MainApp = () => {
  return (
    <>
      <AuthHandler />
      <App />
    </>
  );
};

export default MainApp;
