import React from 'react'
import { Link } from 'react-router-dom'



const MerchantBanner = () => {
  return (
    <>
    <section>
        <header>
            <div className='merchant-edit-banner'>
                <img className="banner-mer" src='/images/merchant/banner.jpg' alt='banner'/>
                <div className='shop-logo-div-new'>
                    <img className="shop-logo" src='/images/merchant/shop-logo.png' alt='logo'/>
                    <img className='camera-icon' src='/images/merchant/cameraicon.png' alt='camera icon'/>
                </div>
                <img className='camera-icon-2' src='/images/merchant/cameraicon.png' alt='camera icon'/>
            </div>
            <Link to={'/'}>
                 <img className='previous-arrow' src='/images/arrow_back.png' alt='back-icon'/>
            </Link>
           
        </header>

       
    </section>

    </>
  )
}

export default MerchantBanner