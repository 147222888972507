import React from 'react'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
  return (
    <>
        <nav className='nav-main'>
            <ul className='nav-list'>
                    <NavLink to="/"><li>New Order</li></NavLink>
                    <NavLink to="/prepare-order"><li>Prepare Order</li></NavLink>
                    <NavLink to="/prepare-delivery"><li>Prepare Delivery</li></NavLink>
                    <NavLink to="/delivery-process"><li>Delivery Process</li></NavLink>
                    <NavLink to="/delivery-done"><li>Delivery Done</li> </NavLink>  
            </ul>
        </nav>
    </>
  )
}

export default NavBar