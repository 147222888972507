import React, { useEffect, useState, useContext }  from 'react';
// import { VscAccount } from "react-icons/vsc";
import { Link, useParams } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
// import Tick from './Tick';

const MerchantDetail = () => {
    const auth = useContext(AuthContext);
    let [user,setUser] = useState({});
    const [showModal, setShowModal] = useState(false);
    let [inputValue, setInputValue] = useState({
        name: "",
        mobile: "",
        email: "",
        description: "",
        room_no: "",
        id: ""
    });

    let {name, mobile, email, description, id} = inputValue;
    
    let [formSubmit, setFormSubmit] = useState(false);

    let userId = useParams().userId;
    console.log(useParams());

    useEffect(() => {
      console.log('response');
        const fetchUser = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_merchant_profile/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                if (!responseData.ok) {
                 throw new Error('Failed to fetch shops');
                }
                const response = await responseData.json();
                console.log(response);
                setUser(response.user);
                setInputValue({
                    name: response.user.name,
                    mobile: response.user.mobile,
                    email: response.user.email,
                    description: response.user.description,
                    id: userId
                })
            } catch (err) {}
        };
        fetchUser();
    }, []);

    let handleSubmit = async () => {
        await fetch('https://ybot-backend.salvator.app/api/update_merchant_profile/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify(inputValue)
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to add to basket');
            }
            setFormSubmit(false);
            setShowModal(true);
          })
          .catch(error => {
            console.error('Error adding to basket:', error);
          });
    }

    useEffect(() => {
        console.log(user);
        if(formSubmit){
            handleSubmit();
        }
    }, [formSubmit])

    let handleButtonClick = (e) => {
        e.preventDefault();
        setFormSubmit(true);
    }

    let handleInput = (e) => {
        let {name, value} = e.target;
        setInputValue({...inputValue, [name]: value})
    }  
    
    const handleCloseModal = () => {
        setShowModal(false);
      };

  return (
    <>
    <form className='user-profile-form' onSubmit={handleButtonClick}>
    <main className='main-merchant-edit'>
        <div className='merchant-name'>
            <img src='/images/merchant/account_circle.png' alt="profile-icon"/>
            <input type='text' placeholder='Enter your name' name='name' onChange={handleInput} value={name}/>
        </div>
        <div className='merchant-name'>
            <img src='/images/merchant/call.png' alt="call-icon"/>
            <input type='number' placeholder='Enter your Mobile number' name='mobile' onChange={handleInput} value={mobile}/>
        </div>
        <div className='merchant-name'>
            <img src='/images/merchant/mail.png' alt="profile-icon"/>
            <input type='email' placeholder='Enter your mail' name='email' onChange={handleInput} value={email}/>
        </div>
        <div className='merchant-name'>
            <img src='/images/merchant/description.png' alt="profile-icon"/>
            <input type='text' placeholder='Description' name='description' onChange={handleInput} value={description}/>
        </div>
        <div className='voh-submit-over'>
            <button className='voh-submit' type='submit'>Submit</button>
       </div> 
        </main>
    </form>
    {/* <Tick isOpen={showModal} onClose={handleCloseModal}/> */}
    <Link to={'/order-history'}>
    <button className='voh-btn' type='submit'>View Order History</button>
    </Link>
    </>
  )
}

//   return (
//     <>
//          <main className='main-merchant-edit'>
//             <div className='merchant-name'>
//                <img src='./images/merchant/account_circle.png' alt='profile icon'/>
//                 <input type="text" placeholder='Enter your name'/>
//             </div>
//             <div className='merchant-name'>
//                <img src='./images/merchant/call.png' alt='call icon'/>
//                 <input type="text" placeholder='Enter mobile number'/>
//             </div>
//             <div className='merchant-name'>
//                <img src='./images/merchant/mail.png' alt='mail icon'/>
//                 <input type="text" placeholder='Enter your email'/>
//             </div>
//             <div className='merchant-name'>
//                <img src='./images/merchant/description.png' alt='description icon'/>
//                 <input type="text" placeholder='Description' minLength="4" maxLength="100"/>
//             </div>


//             <button className='voh-btn'>View Order History</button>
//          </main>
//     </>
//   )
// }

export default MerchantDetail