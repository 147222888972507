import React from 'react';
import MerchantBanner from '../Components/MerchantBanner';
import MerchantOrderStatus from '../Components/MerchantOrderStatus'

const MerchantPage2 = () => {
  return (
    <>
        <MerchantBanner/>
        <MerchantOrderStatus/>
    </>
  )
}

export default MerchantPage2