import React from 'react'
import  RoomItem from './RoomItem'
// const orderItem =[
//     {
//         image:"./images/food1.png",
//         itemName:"Spicy Noodle",
//         itemPara:"Requiment or Optional form customer",
//         amt:"Amount",
//         num:"2",
//         alt:"food"
//     },
//     {
//         image:"./images/food2.png",
//         itemName:"Spicy Noodle",
//         itemPara:"Requiment or Optional form customer",
//         amt:"Amount",
//         num:"3",
//         alt:"food"
//     },
//     {
//         image:"./images/food3.png",
//         itemName:"Spicy Noodle",
//         itemPara:"Requiment or Optional form customer",
//         amt:"Amount",
//         num:"1",
//         alt:"food"
//     }
// ]

export default function RoomData({orderItem}){
    return(
        <div>
            {
                orderItem.map((ce,index) => (
                    <RoomItem key={index} value={ce} />
                ))
            }
        </div>
    )
}



