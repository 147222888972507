import React, {useEffect, useState, useContext} from 'react'
import { Link, useParams } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';

const MerchantOrderDetailEdit2 = () => {
    const auth = useContext(AuthContext);
    let orderId = useParams().orderId;
    const [orderList, setOrderList] = useState({});
    const [items, setItems] = useState([]);
    useEffect(() => {        
        const fetchOrder = async () => {
            try{
                const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_order_detail/${orderId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                      }
                });
                if (!responseData.ok) {
                 throw new Error('Failed to fetch order list');
                }
                const response = await responseData.json();
                console.log(response.merchantOrder[0]);
                setOrderList(response.merchantOrder[0]);
                setItems(response.merchantOrder[0].items)
            } catch (err) {}
        };
        fetchOrder();
    }, []);

  return (
    <>

    <section className='relative-top'>

        <div className='order-romm-flex '>
            <div className='inner-room-flex'>
                <div>
                    <img className="loc-icon" src='/images/iocation.png'  alt='location icon'/>
                </div>
                <div className='revise-div'>
                    <p className='r-text-room'>Room {orderList.roomNo}</p>
                    {/* <p className='r-text-date'><span>25 Mar 2024</span>  <span>15:45</span></p> */}
                    <p className='r-text-date'><span>{orderList.createdAt}</span></p>
                </div>
            </div>
            <div className='status-div'>
                <p className='r-text-price'>฿{orderList.totalCost}</p>
                <p className='r-text-status'>{orderList.merchantStatus}</p>
            </div>
        </div>
        <p className='od-detail'>Order Detail:</p>

       {items.map((data,index) => {
        return (
            <div className='room-order-new'>
            <div className='inner-room-flex'>
                <div>
                    <img src='/images/hotel1.png' alt="img"></img>
                </div>
                <div>
                    <h4 className='r-itemname'>{data.dishName}</h4>
                    <p className='r-itempara'>฿{data.price}</p>
                </div>
            </div>
            <div>
                <p className='r-itemamt'>Quantity</p>
                <h2 className='r-itemnum'>{data.quantity}</h2>
            </div>
        </div>
        )
       })}
         {/* <div className='room-order-new'>
          <div className='inner-room-flex'>
              <div>
                  <img src='/images/hotel1.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Spicy Noodles</h4>
                  <p className='r-itempara'>฿200</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>1</h2>
          </div>
      </div>
      <div className='room-order-new'>
          <div className='inner-room-flex'>
              <div>
                  <img src='/images/hotel2.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Thomiyam khung</h4>
                  <p className='r-itempara'>฿100</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>1</h2>
          </div>
      </div>
      <div className='room-order-new'>
          <div className='inner-room-flex'>
              <div>
                  <img src='/images/hotel3.png' alt=""></img>
              </div>
              <div>
                  <h4 className='r-itemname'>Thai rice</h4>
                  <p className='r-itempara'>฿200</p>
              </div>
          </div>
          <div>
              <p className='r-itemamt'>Amount</p>
              <h2 className='r-itemnum'>2</h2>
          </div>
      </div> */}

      <Link to={'/order-history'}>
      <button className='voh-btn'>Back</button>
      </Link>
      </section>
    </>
  )
}

export default MerchantOrderDetailEdit2
