import React from 'react'
import { Link } from 'react-router-dom'
import RoomItem from '../RoomDetails/RoomItem';
// import ButtonMerchant from '../RoomDetails/ARButton'




const Accept2 = ({data}) => {

//    const [order,setOrder] =  useState({})
    console.log(data);
    // const[] = data
    // const [data1,data2]= data;
    // console.log(data1);

  return (
    <>
    <section  className='room-sec'>
          <div className='room-text'>
            <div className='tick'>
                <img src='./images/icon-done.png' alt='done icon'/>
            </div>
           
            <div className='flex-grow'>
               <h2 className='room-no'>Room 102</h2>
                <p className='room-date'>Order Time: 15:45 | 05/MAR/2024</p>
            </div>
            <div className='cc-btn'>
                <Link to="#">Contact Customer</Link>
            </div>
        </div>
        <div className=''>
            {
                data.map((ce)=>{
                    console.log(ce)
                    // let {itemName,itemPara,image,Amount,num} = ce
                    return(
                        // <div>{itemName}</div>
                        <RoomItem value={ce}/>
                    )
                })
            }
        </div>
        {/* <ButtonMerchant/> */}
        {/* <div>
            {
                data2.map((ce)=>{
                    console.log(ce)
                    // let {itemName,itemPara,image,Amount,num} = ce
                    return(
                        // <div>{itemName}</div>
                        <RoomItem value={ce}/>
                    )
                })
            }
        </div> */}
    </section>

    </>
  )
}

export default Accept2