import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate } from 'react-router';
import { AuthContext } from '../../context/AuthContext';

const YbotButton = ({orderId, callOrder, placeItemCodeDB, bridgeIdDB}) => {
  console.log(placeItemCodeDB);
    const auth = useContext(AuthContext);
    let [robottoken, setRobotToken] = useState(null);
    let [token, setToken] = useState(null);
    let [locationList, setLocationList] = useState([]);
    let [bridgeId, setBridgeId] = useState(null);
    let [placeItemCode, setPlaceItemCode] = useState(null);
    let [pickupCode, setPickupCode] = useState(null);
    let [startPoint, setStartPoint] = useState(null);
    const [deliveryState, setDeliveryState] = useState([]);
    // let [startPoi, setStartPoi] = useState();
    // let [targetPoi, setTargetPoi] = useState();
    // let [fromLocation, setFromLocation] = useState("");
    // let [toLocation, setToLocation] = useState("");
    let [taskId, setTaskId] = useState();
    let navigate = useNavigate();
    let [orderData, setOrderData] = useState([]);
    let shopId = '660a620ff16175693097b78f';

   
    if(callOrder){
      console.log('orderId');
      console.log(orderId);
      console.log('callOrder');
      console.log(callOrder);
    }
    

  // useEffect(() => {
  //   let reserveRobot = async () => {
  //     try {
  //       //! First API call to generate token
  //       // Get token API
  //       const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token',{
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           "app_id": "SalvatorTech",
  //           "app_secret": "324d5a3e0efa7629b42d5869b222261c"
  //         })
  //           }
  //         );
  //       const response = await tokenResponse.json();
  //       const generatedToken =  response.data.access_token;
  //       setToken(generatedToken);

  //       if(generatedToken){

  //       //! Fetch location list from robot
  //       //* Need to get user location and merchant location and then need to get poi_sid for both the location and pass it to secondApiResponse body start_poi & target_poi
  //       const locationList = await fetch('https://api.yogorobot.com/app-bridge/api/v1/poi?site_uid=1230', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization : 'Bearer ' + generatedToken
  //         },
  //       });
      
  //       const locationListResult = await locationList.json();
  //       const locations = locationListResult.data.interest;
  //       setLocationList(locations);
  //       // loc.group_poi.poi_sid
  //       // console.log(locations);

  //         //! Second API call with the generated token and other information
  //        //  Place an order for delivery (3-digit code) API
  //        const secondApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/reserved?site_uid=1230', {
  //          method: 'POST',
  //          headers: {
  //            'Content-Type': 'application/json',
  //            Authorization : 'Bearer ' + generatedToken
  //          },
  //          body: JSON.stringify({
  //            "start_poi" : 18,
  //            "target_poi" : 15,
  //            "task_external_id" : "ext310101",
  //            "sender_info" : {
  //                "user_name" : "sekar",
  //                "user_phone" : "0655210888"
  //            },
  //            "receiver_info" : {
  //                "user_name" : "Test",
  //                "user_phone" : "0655210999"
  //            },
  //            "pickup_timeout": 10  
  //          })
          
  //        });
         
  //        const secondApiResult = await secondApiResponse.json();
  //        console.log(secondApiResult);
  //        const bridgeLinkUid = secondApiResult.data.bridge_link_uid;
  //        setBridgeId(bridgeLinkUid);

  //        if(bridgeLinkUid){
  //             //! Third API call with the generated token and other information
  //             // Query bridge tasks API
  //             const thirdApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
  //               method: 'POST',
  //               headers: {
  //                 'Content-Type': 'application/json',
  //                 Authorization : 'Bearer ' + generatedToken
  //               },
  //               body: JSON.stringify({
  //                 "bridge_link_uids" : [bridgeLinkUid]
  //               })
                
  //             });
              
  //             const thirdApiResult = await thirdApiResponse.json();
  //             const placeItemCode = thirdApiResult.data[0].dispatch_info.logical_code;
  //             const pickupCode = thirdApiResult.data[0].dispatch_info.pickup_code;
  //             const startPoint = thirdApiResult.data[0].tasks[0].start_poi;
  //             const apiTaskId = thirdApiResult.data[0].tasks[0].task_id;
  //              console.log('thirdApiResult');
  //               console.log(thirdApiResult.data[0]);
  //              console.log(startPoint);
  //              console.log(placeItemCode);
  //             setPlaceItemCode(placeItemCode);
  //             setPickupCode(pickupCode);
  //             setStartPoint(startPoint);
  //             setTaskId(apiTaskId);

  //            if(startPoint){
  //           //! Fourth API call with the generated token and other information
  //           // Summon of Robot API
          
  //           const fourthApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/summon?site_uid=1230', {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               Authorization : 'Bearer ' + generatedToken
  //             },
  //             body: JSON.stringify({
  //               "target_poi" : startPoint,
  //               "task_external_id": "ext310101",
  //               "duration": 10
  //             })
  //           });
            
  //           const fourthApiResult = await fourthApiResponse.json();

  //           // console.log('fourthApiResult');
  //           // console.log(fourthApiResult);
  //           const summonBridgeId = fourthApiResult.data.bridge_link_uid;
  //           // console.log(taskCode);
        
  //           // if(fourthApiResult){
  //           //   navigate('/delivery-status', { state: { token : generatedToken, bridgeId : bridgeLinkUid,fromLocation : fromLocation, toLocation: toLocation, taskId: summonBridgeId  } });
  //           // }

  //         }
  //         }
  //         }
  //     }catch(err) {
  //       console.log(err);
  //     }
  //   }
  //   if (callOrder && orderId === callOrder){
  //       reserveRobot()
  //   }
    
  // }, [callOrder])


  // // Fetch delivery status starts
  // useEffect(() => {
  //   if (orderId === callOrder) {
  //     //  console.log('Token:', token);
  //     //  console.log('BridgeId:', bridgeId);
  //     // Now you can use token and otherData as needed
  //     // Fourth API call with the generated token and other information
  //       const fetchDeliveryStateRecords = async () => {
  //         try {
  //       const thirdApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization : 'Bearer ' + token
  //         },
  //         body: JSON.stringify({
  //           "bridge_link_uids" : [bridgeId]
  //       })
  //         // Other data you need to pass
  //       });
        
  //       const thirdApiResult = await thirdApiResponse.json();
  //       // console.log(thirdApiResult);
  //       if(thirdApiResult.data[0]){
  //       const placeItemCode = thirdApiResult.data[0].dispatch_info.logical_code;
  //       const pickupCode = thirdApiResult.data[0].dispatch_info.pickup_code;
  //       const startPoint = thirdApiResult.data[0].tasks[0].start_poi;
  //       const deliveryStatus = thirdApiResult.data[0].tasks[0].delivery_state_records;
  //       // console.log('status');
  //       // console.log(thirdApiResult.data[0]);
  //       // console.log(startPoint);
  //        setPlaceItemCode(placeItemCode);
  //        setPickupCode(pickupCode);
  //        setStartPoint(startPoint);
  //       if (deliveryStatus && deliveryStatus.length > 0) {
  //         setDeliveryState(deliveryStatus[deliveryStatus.length - 1]);
  //       }
  //       // setDeliveryState(deliveryStatus);
  //       }
  //     }
  //     catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }
  //   fetchDeliveryStateRecords();
  //     // Make sure to handle cases where token or otherData might be undefined
  //   }
  // }, [callOrder]);
  // Fetch delivery status ends


  // Use a ref to track if the API call has been made
  const isApiCalled = useRef(false);

  useEffect(() => {
    const reserveRobot = async () => {
      try {
        //! First API call to generate token
        const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            app_id: "SalvatorTech",
            app_secret: "324d5a3e0efa7629b42d5869b222261c",
          }),
        });
        const tokenData = await tokenResponse.json();
        const generatedToken = tokenData.data.access_token;
        console.log(generatedToken);
        setToken(generatedToken);

        if (generatedToken) {
          //! Fetch location list from robot
          const locationListResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/poi?site_uid=1230', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${generatedToken}`,
            },
          });
          const locationListResult = await locationListResponse.json();
          setLocationList(locationListResult.data.interest);

          //! Second API call to place an order for delivery
          const secondApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/reserved?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${generatedToken}`,
            },
            body: JSON.stringify({
              start_poi: 18,
              target_poi: 15,
              task_external_id: "ext310101",
              sender_info: {
                user_name: "sekar",
                user_phone: "0655210888",
              },
              receiver_info: {
                user_name: "Test",
                user_phone: "0655210999",
              },
              pickup_timeout: 10,
            }),
          });

          const secondApiResult = await secondApiResponse.json();
          const bridgeLinkUid = secondApiResult.data.bridge_link_uid;
          console.log(bridgeLinkUid);
          setBridgeId(bridgeLinkUid);

          if (bridgeLinkUid) {
            //! Third API call to query bridge tasks
            const thirdApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${generatedToken}`,
              },
              body: JSON.stringify({
                bridge_link_uids: [bridgeLinkUid],
              }),
            });

            const thirdApiResult = await thirdApiResponse.json();
            const taskData = thirdApiResult.data[0];
            console.log('Setting placeItemCode:', taskData.dispatch_info.logical_code);  // Log before setting state
            setPlaceItemCode(taskData.dispatch_info.logical_code);
            console.log('Setting pickupCode:', taskData.dispatch_info.pickup_code);  // Log before setting state
            setPickupCode(taskData.dispatch_info.pickup_code);
            setStartPoint(taskData.tasks[0].start_poi);
            setTaskId(taskData.tasks[0].task_id);

            //! Fourth API call to summon the robot
            if (taskData.tasks[0].start_poi) {
              const fourthApiResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task/delivery/summon?site_uid=1230', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${generatedToken}`,
                },
                body: JSON.stringify({
                  target_poi: taskData.tasks[0].start_poi,
                  task_external_id: "ext310101",
                  duration: 10,
                }),
              });

              const fourthApiResult = await fourthApiResponse.json();
              const summonBridgeId = fourthApiResult.data.bridge_link_uid;
              console.log('summonBridgeId');
              console.log(summonBridgeId);
              if(summonBridgeId){
              try{
                let updateplaceItemCode = async () => {
                  let response = await fetch('https://ybot-backend.salvator.app/api/update_order_place_item_code', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${auth.token}`
                    },
                    body: JSON.stringify({ orderId: orderId, placeItemCode: taskData.dispatch_info.logical_code , pickupCode: taskData.dispatch_info.pickup_code,  bridgeId:bridgeLinkUid }),
                  })
                  let data = await response.json();
                  console.log(data);
                  if (!response.ok) {
                    throw new Error('Failed to update, Please try again ');
                  }     
              }
              updateplaceItemCode();
              } catch(err) {
                  console.log(err);
              }
            }
                  
              // navigate('/delivery-status', {
              //   state: {
              //     token: generatedToken,
              //     bridgeId: summonBridgeId,
              //     startPoint,
              //     placeItemCode,
              //     pickupCode,
              //   },
              // });
            }
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (callOrder && orderId === callOrder && !isApiCalled.current) {
      isApiCalled.current = true;
      reserveRobot();
    }
  }, [callOrder, orderId, navigate]);

  useEffect(() => {
    if(callOrder){
    const fetchDeliveryStateRecords = async () => {
      if (token && bridgeId && orderId === callOrder) {
        try {
          const response = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              bridge_link_uids: [bridgeId],
            }),
          });
          const result = await response.json();
          const taskData = result.data[0];
          setPlaceItemCode(taskData.dispatch_info.logical_code);
          setPickupCode(taskData.dispatch_info.pickup_code);
          setStartPoint(taskData.tasks[0].start_poi);
          if (taskData.tasks[0].delivery_state_records.length > 0) {
            setDeliveryState(taskData.tasks[0].delivery_state_records.slice(-1)[0]);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchDeliveryStateRecords();
  } 
  else {
    const fetchDeliveryStateRecords = async () => {
      console.log('bridgeIdDB');
      console.log(bridgeIdDB);
      if ( bridgeIdDB && orderId) {
        try {
          const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            app_id: "SalvatorTech",
            app_secret: "324d5a3e0efa7629b42d5869b222261c",
          }),
        });
        const tokenData = await tokenResponse.json();
        const generatedToken = tokenData.data.access_token;
        console.log(generatedToken);
        setRobotToken(generatedToken);

          const response = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${generatedToken}`,
            },
            body: JSON.stringify({
              bridge_link_uids: [bridgeIdDB],
            }),
          });
          const result = await response.json();
          const taskData = result.data[0];
          setPlaceItemCode(taskData.dispatch_info.logical_code);
          setPickupCode(taskData.dispatch_info.pickup_code);
          setStartPoint(taskData.tasks[0].start_poi);
          if (taskData.tasks[0].delivery_state_records.length > 0) {
            setDeliveryState(taskData.tasks[0].delivery_state_records.slice(-1)[0]);

            if(taskData.tasks[0].delivery_state_records.slice(-1)[0].to === 'collected' || taskData.tasks[0].delivery_state_records.slice(-1)[0].to !== 'success' && taskData.tasks[0].delivery_state_records.slice(-1)[0].to !== 'created' ){
              try{
                let updateStatus = async () => {
                  let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                       'Authorization': `Bearer ${auth.token}`
                    },
                    body: JSON.stringify({ orderId: orderId, status: 'ongoingDelivery'  }),
                  })
                  let data = await response.json();
                  console.log(data);
                  if (!response.ok) {
                    throw new Error('Failed to update, Please try again ');
                  }     
              }
              updateStatus();
              } catch(err) {
                  console.log(err);
              }
            }

          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchDeliveryStateRecords();
  }
  }, [deliveryState, callOrder, token, bridgeId, orderId]);

  useEffect(() => {
    if(callOrder){
    console.log('deliveryState');
    console.log(deliveryState);
    if(deliveryState.to === 'collected'){
    try{
      let updateStatus = async () => {
        let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ${auth.token}`
          },
          body: JSON.stringify({ orderId: orderId, status: 'ongoingDelivery'  }),
        })
        let data = await response.json();
        console.log(data);
        if (!response.ok) {
          throw new Error('Failed to update, Please try again ');
        }     
        navigate('/delivery-process', { state: { orderId, callOrder, token, bridgeId  } })
    }
    updateStatus();
    } catch(err) {
        console.log(err);
    }
  }
  else if(deliveryState.to === 'success'){
    try{
      let updateStatus = async () => {
        let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ${auth.token}`
          },
          body: JSON.stringify({ orderId: orderId, status: 'deliveryCompleted'  }),
        })
        let data = await response.json();
        console.log(data);
        if (!response.ok) {
          throw new Error('Failed to update, Please try again ');
        }     
        navigate('/delivery-done')
    }
    updateStatus();
    } catch(err) {
        console.log(err);
    }
  }
}
}, [callOrder, deliveryState])

  return (
    <div className='robot-no-pas'>
        <div className='robot-number'>
            <p>Robot No:</p>
            <h2>YBot 01</h2>
        </div>
        <div className='robot-password'>
            <p>Collect Item  Password:</p>
            {orderId && callOrder && orderId === callOrder ?  <h3>{placeItemCode && placeItemCode}</h3> : <h3>{placeItemCodeDB}</h3> }
            {/* <h3>{placeItemCode && placeItemCode}</h3> */}
            {/* <h3>123#</h3> */}
        </div>
    </div>
  )
}

export default YbotButton