import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router';
import { AuthContext } from '../../context/AuthContext';

const Done = ({orderId}) => {
  const auth = useContext(AuthContext);
  let [doneClick, setDoneClick] = useState(false);
  let navigate = useNavigate();
  
  let handleDone = () => {
      setDoneClick(true);
  }

  // useEffect(() => {
  //   if(doneClick){
  //       let updateStatus = async () => {
  //           let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({ orderId: orderId, status: 'preparation-done', updatedAt: Date.now()  }),
  //           })
  //           let data = await response.json();
  //           if (!response.ok) {
  //             throw new Error('Failed to update, Please try again ');
  //           }
  //           setDoneClick(false);
  //           navigate('/prepare-delivery', { state: { orderId : orderId } })
  //       }
  //       updateStatus();
  //   }
  // },[doneClick])

  useEffect(() => {
    if (doneClick) {
      const updateStatus = async () => {
        try {
          const response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify({ orderId, status: 'preparation-done', updatedAt: Date.now() }),
          });
          if (!response.ok) {
            throw new Error('Failed to update, please try again.');
          }
          const data = await response.json();
          console.log('Update successful:', data);
          navigate('/prepare-delivery', { state: { orderId } });
        } catch (error) {
          console.error('Error updating status:', error.message);
          // Optionally, set an error state here to display an error message to the user
        } finally {
          setDoneClick(false);
        }
      };

      updateStatus();
    }
  }, [doneClick, navigate, orderId]);

  return (
    <div className='doneBtn'>
         <Link to='#'><button className='acc-btn' id="width-done" onClick={handleDone}>Done</button></Link>
    </div>
  )
}

export default Done