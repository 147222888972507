import React from 'react';
import MerchantBanner from '../Components/MerchantBanner';
import  MerchantDetail from '../Components/MerchantDetail'

const MerchantProfileEdit1 = () => {
  return (
    <>
    <MerchantBanner/>
    < MerchantDetail/>
    </>
  )
}

export default MerchantProfileEdit1