import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router';

import { AuthContext } from '../../context/AuthContext';

const ButtonMerchant = ({orderId}) => {
  const auth = useContext(AuthContext);
  let [acceptClick, setAcceptClick] = useState(false);
  let navigate = useNavigate();
  
  let handleAccept = () => {
    console.log('button clicked');
      setAcceptClick(true);
  }

  useEffect(() => {
    if(acceptClick){
        let updateStatus = async () => {
            let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                 'Authorization': `Bearer ${auth.token}`
              },
              body: JSON.stringify({ orderId: orderId, status: 'accepted'  }),
            })
            let data = await response.json();
            if (!response.ok) {
              throw new Error('Failed to update, Please try again ');
            }
            navigate('/prepare-order')
        }
        updateStatus();
    }
  },[acceptClick])

  return (
    <div className='a-r-btn'>
        <button className='acc-btn' onClick={handleAccept}>Accept</button>
        <button className='rej-btn'>Reject</button>
    </div>
  )
}

export default ButtonMerchant;