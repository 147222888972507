import React, {useContext} from 'react'
import NavBar from './NavBar'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext';

const Banner = () => {
    const auth = useContext(AuthContext);
  return (
    <>
        <header className='overall-header'>
            <div className='floating-main-div'>
                <div className='banner-div'>
                    <img src='./images/banner.png' alt='banner'/>
                </div>
                <div>
                    <div className='floating-connect'>
                        <div className='inner-shop'>
                            <div className='shop-logo-div'>
                                <img src='./images/shop-icon.png' alt='shop icon'/>
                                <p>Home made foods</p>
                            </div>
                            <div>
                                <Link to={`/profile/${auth.userId}`}>
                                <img src='/images/setting-icon.png' alt='setting icon'/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='nav-compt'>
                <NavBar/>
            </div>
           
        </header>
    </>
  )
}

export default Banner