import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router';
import { AuthContext } from '../../context/AuthContext';

const DeliveryInProgress = ({state = {}, data = {} }) => {
  //  console.log(orderId);
  //  console.log(callOrder);
  //  console.log(token);
  //  console.log(bridgeId);
  // console.log(data);
  const auth = useContext(AuthContext);
  let [robottoken, setRobotToken] = useState(null);
  const [deliveryState, setDeliveryState] = useState([]);

  // const {
  //   orderId = null,
  //   callOrder = null, // Default to an empty function
  //   token = '',
  //   bridgeId = null
  // } = state || {};

  const {
    orderId = data.orderId,
    callOrder = data.callOrder ? data.callOrder : '',
    token = data.token ? data.token: '',
    bridgeId = data.bridgeId ? data.bridgeId : ''
  } = state;

//  console.log(orderId);
//    console.log(callOrder);
//    console.log(token);
//    console.log(bridgeId);
  let navigate = useNavigate();

  useEffect(() => {
    if(callOrder){
    const fetchDeliveryStateRecords = async () => {
      if (token && bridgeId && orderId === callOrder) {
        try {
          const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            app_id: "SalvatorTech",
            app_secret: "324d5a3e0efa7629b42d5869b222261c",
          }),
        });
        const tokenData = await tokenResponse.json();
        const generatedToken = tokenData.data.access_token;
        console.log(generatedToken);
        setRobotToken(generatedToken);

          const response = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${generatedToken}`,
            },
            body: JSON.stringify({
              bridge_link_uids: [bridgeId],
            }),
          });
          const result = await response.json();
          // console.log('status result');
          // console.log(result);
          const taskData = result.data[0];
          if (taskData.tasks[0].delivery_state_records.length > 0) {
            setDeliveryState(taskData.tasks[0].delivery_state_records.slice(-1)[0]);
            console.log(taskData.tasks[0].delivery_state_records.slice(-1)[0]);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchDeliveryStateRecords();
  }
  else {
    const fetchDeliveryStateRecords = async () => {
      if (bridgeId && orderId) {
        try {
          const tokenResponse = await fetch('https://api.yogorobot.com/app-bridge/api/v1/auth/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            app_id: "SalvatorTech",
            app_secret: "324d5a3e0efa7629b42d5869b222261c",
          }),
        });
        const tokenData = await tokenResponse.json();
        const generatedToken = tokenData.data.access_token;
        console.log(generatedToken);
        setRobotToken(generatedToken);

          const response = await fetch('https://api.yogorobot.com/app-bridge/api/v1/task?site_uid=1230', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${generatedToken}`,
            },
            body: JSON.stringify({
              bridge_link_uids: [bridgeId],
            }),
          });
          const result = await response.json();
          // console.log('status result');
          // console.log(result);
          const taskData = result.data[0];
          if (taskData.tasks[0].delivery_state_records.length > 0) {
            setDeliveryState(taskData.tasks[0].delivery_state_records.slice(-1)[0]);
            console.log(taskData.tasks[0].delivery_state_records.slice(-1)[0]);
            if(taskData.tasks[0].delivery_state_records.slice(-1)[0].to === 'success' ){
              try{
                let updateStatus = async () => {
                  let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${auth.token}`
                    },
                    body: JSON.stringify({ orderId: orderId, status: 'deliveryCompleted'  }),
                  })
                  let data = await response.json();
                  console.log(data);
                  if (!response.ok) {
                    throw new Error('Failed to update, Please try again ');
                  }     
              }
              updateStatus();
              } catch(err) {
                  console.log(err);
              }
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchDeliveryStateRecords();
  }
  }, [deliveryState, callOrder, token, bridgeId, orderId]);

  useEffect(() => {
    if(callOrder){
    console.log('deliveryState');
    console.log(deliveryState);
    if(deliveryState.to === 'success'){
    try{
      let updateStatus = async () => {
        let response = await fetch('https://ybot-backend.salvator.app/api/update_merchant_order_Status', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          },
          body: JSON.stringify({ orderId: orderId, status: 'deliveryCompleted'  }),
        })
        let data = await response.json();
        console.log(data);
        if (!response.ok) {
          throw new Error('Failed to update, Please try again ');
        }     
        navigate('/delivery-done')
    }
    updateStatus();
    } catch(err) {
        console.log(err);
    }
  }
}
}, [callOrder, deliveryState])

  return (
    <>
        <div className='robot-no-pas'>
            <div className='robot-number'>
                <p>Robot No:</p>
                <h2>YBot 01</h2>
            </div>
            <div className='robot-password flex-robot-password'>
                <img src='./images/icon-progress.png' alt='icon'/>
                {/* <p id="dip-text">Delivery in progress</p> */}
                <p id="dip-text">{deliveryState.to ? deliveryState.to : 'Delivery in progress'}</p>
                
            </div>
        </div>

    </>
  )
}

export default DeliveryInProgress