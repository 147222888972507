import React from 'react';
import MerchantBanner from '../Components/MerchantBanner';
import  MerchantOrderDetailEdit2 from '../Components/MerchantOrderDetailEdit2'

const MerchantProfileEdit2 = () => {
  return (
    <>
          <MerchantBanner/>
          < MerchantOrderDetailEdit2/>

    </>
  )
}

export default MerchantProfileEdit2