import React, {useState, useEffect, useContext} from 'react'
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const MerchantOrderStatus = () => {
    const auth = useContext(AuthContext);
    const [orderList, setOrderList] = useState([]);

    useEffect(() => {        
          const fetchOrder = async () => {
              try{
                  const responseData = await fetch(`https://ybot-backend.salvator.app/api/get_all_merchant_order/${auth.userId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                      }
                  });
                  if (!responseData.ok) {
                   throw new Error('Failed to fetch order list');
                  }
                  const response = await responseData.json();
                   setOrderList(response.merchantOrder);
              } catch (err) {}
          };
          fetchOrder();
      }, []);

  return (
    <>
        <aside>
        {orderList.map((data, index) => {
            return(
            <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room {data.roomNo}</h3>
                        <p>{data.createdAt}</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿{data.totalCost}</h4>
                        <p className={data.merchantStatus === 'deliveryCompleted' ? 'order-completed' : 'order-cancel'}>{data.merchantStatus}</p>
                    </div>
                    <Link to={`/order-detail/${data.orderId}`}>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                    </Link>
                </div>
            </div>
            )
        })
        }

            
            {/* <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room 102</h3>
                        <p>05 Mar 2024, 15:45</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿100</h4>
                        <p className='order-cancel-customer'>Order Canceled by customer</p>
                    </div>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                </div>
            </div>
            
            <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room 102</h3>
                        <p>05 Mar 2024, 15:45</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿100</h4>
                        <p className='order-cancel'>Order Completed</p>
                    </div>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                </div>
            </div>
            
            <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room 102</h3>
                        <p>05 Mar 2024, 15:45</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿100</h4>
                        <p className='order-cancel-customer'>Order Canceled by customer</p>
                    </div>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                </div>
            </div>
            
            <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room 102</h3>
                        <p>05 Mar 2024, 15:45</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿100</h4>
                        <p className='order-completed'>Order Completed</p>
                    </div>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                </div>
            </div>
           
            <div className='all-div-flex'>
                <div className='room-status'>
                    <div>
                        <img className='mini-loc' src='./images/iocation.png' alt='location icon'/>
                    </div>
                    <div className='room-num-detail'>
                        <h3>Room 102</h3>
                        <p>05 Mar 2024, 15:45</p>
                    </div>
                </div>
                <div className='room-status'>
                    <div className='room-bhat'>
                        <h4>฿100</h4>
                        <p className='order-completed'>Order Completed</p>
                    </div>
                    <p><img src='./images/circle-right.png' alt='arrow icon'></img></p>
                </div>
            </div> */}

        </aside>

    </>
  )
}

export default MerchantOrderStatus